@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

:root {
  --footer-height: 3.3rem;
  --content-height: calc(100vh - var(--footer-height));
}

.footer-height {
  min-height: var(--footer-height);
}

.content-height {
  min-height: var(--content-height);
}

mark {
  background: none;
}

.pulse-animation {
  animation: pulse 1.5s infinite;
}

.test {
  background: #7c5134;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.05);
  }
  60% {
    transform: scale(1.05);
  }
  90% {
    transform: scale(1.001);
  }
  100% {
    transform: scale(1);
  }
}
